<template>
  <div class="writing-2">
    <div>
      <div
          class="writing-wrapper"
          ref="writingWrapper"
          @down="showEditBox = false"
      >
        <!--PARAGRAPH-->

        <div class="writing-marker p-2" ref="writingMarker">
          <pre
              class="content text-subtitle-1 text-justify font-weight-medium p-3"
              style="border-radius: 5px"
              id="prep-para"
              @mouseup="getSelectionText($event)"
              ref="paragraph"
              v-html="paragraph"
          ></pre>
        </div>

        <div style="font-weight: bold; margin-top: 10px">
          <span style="color: red">
            {{ characterCount }}
          </span>
          characters, <span style="color: red">{{ wordCount }}</span> words,
          <span style="color: red">{{ sentenceCount }}</span> sentences
        </div>
        <!--END PARAGRAPH-->


        <!-- TRIEU CHECK AI AND PLAGIARISM -->
        <h4 class="pt-20 font-weight-bold" v-if="this.skillId==4">Tỷ lệ sử dụng AI và đạo văn trong bài</h4>

        <div
            v-if="!answer.ai_score && !answer.plagiarism_score && this.skillId==4"
            class="pb-20 bg-gray-100 p-4"
        >
          Thông tin về tỷ lệ sử dụng AI và đạo văn trong bài không được trả về do dịch vụ gián đoạn
        </div>

        <div class="pb-10" v-if="answer.ai_score || answer.plagiarism_score">
          <h5 class="font-weight-bold mt-3 text-sm">
            Tỷ lệ sử dụng AI
            <label class="ml-8 text-danger">{{ answer.ai_score ? `${answer.ai_score}%` : '--' }}</label>
          </h5>

          <div class="pb-10">
            <div
                v-if="answer.ai_check_sentences && answer.ai_check_sentences.length > 0"
                class="bg-gray-100 p-4"
            >
              <span
                  v-for="(item, index) in  answer.ai_check_sentences"
                  :key="`text-plagiarism-${index}`"
                  style="line-height: 24px"
                  class="ml-3"
              >
                <small
                    class="position-relative text-danger font-weight-bold text-xs"
                    style="top: -10px"
                >
                    ({{ item.score }}%)
                </small>
                <b
                    v-if="item.score > 40"
                    class=""
                >
                  {{ item.text }}
                </b>
                <label v-if="item.score <= 40">
                  {{ item.text }}
                </label>
              </span>
            </div>

            <div
                v-if="!answer.ai_check_sentences || answer.ai_check_sentences.length <= 0"
                class="bg-gray-100 p-4 text-center font-weight-bold"
            >
              Thông tin về tỷ lệ sử dụng AI trong bài không được trả về do dịch vụ gián đoạn
            </div>
          </div>

          <div class="pb-10">
            <h5 class="font-weight-bold mt-4 text-sm">
              Tỷ lệ đạo văn
              <label
                  class="ml-8 text-danger">{{ answer.plagiarism_score ? `${answer.plagiarism_score}%` : '--' }}</label>
            </h5>

            <div
                v-if="answer.plagiarism_check_sentences && answer.plagiarism_check_sentences.length > 0"
                class="bg-gray-100 p-4"
            >
              <span
                  v-for="(item, index) in answer.plagiarism_check_sentences"
                  :key="`text-plagiarism-${index}`"
                  style="line-height: 24px"
              >
                <b
                    v-if="item.matches.length > 0"
                    class="position-relative text-decoration-underline phrase-plagiarism ml-3"
                >
                  <small
                      class="position-relative text-danger font-weight-bold text-xs"
                      style="top: -10px"
                  >
                    ({{ item.matches[0].score }}%)
                  </small>

                  {{ item.phrase }}

                  <label class="tooltip-plagiarism position-absolute">
                    <strong
                        v-for="(matche, indexMatches) in item.matches"
                        :key="`matche-${indexMatches}`"
                        class=""
                    >
                      {{ matche.text }}
                    </strong>

                    <br/>
                    <a
                        class="float-right font-weight-semibold text-decoration-underline"
                        :href="item.matches[0].url"
                        target="_blank"
                    >
                      Chi tiết nguồn >>
                    </a>
                  </label>
                </b>
                <label v-if="item.matches.length <= 0">
                  {{ item.text }}
                </label>
              </span>
            </div>

            <div
                v-if="!answer.plagiarism_check_sentences || answer.plagiarism_check_sentences.length <= 0"
                class="bg-gray-100 p-4 text-center font-weight-bold"
            >
              Thông tin về tỷ lệ đạo văn trong bài không được trả về do dịch vụ gián đoạn
            </div>
          </div>
        </div>
        <!-- END TRIEU CHECK AI AND PLAGIARISM -->


        <!-- BUBBLE BOX-->
        <div>
          <div class="edit-box-wrapper" :class="{ invisiblity: !showEditBox }">
            <div class="edit-text" :class="{ hide: !showEditBox }">
              <label style="font-weight: bold" class="lock">
                {{ $t("Original") }}
              </label>
              <p class="selected-paragraph">
                {{ selectedText }}
              </p>

              <v-radio-group v-model="selectTagType">
                <v-radio label="Error" color="red" :value="1"></v-radio>

                <v-radio label="Good use" color="green" :value="2"></v-radio>
              </v-radio-group>
              <div v-if="selectTagType == 1">
                <label style="font-weight: bold">
                  {{ $t("Errors") }}
                </label>
                <div>
                  <!--                  <button @click="errorTagsSelected.push(1,2,3)">Click</button>-->
                  <v-autocomplete
                      v-model="errorTagsSelected"
                      :items="errorTags"
                      filled
                      chips
                      item-text="name"
                      item-value="id"
                      multiple
                      @change="updateTags(1)"
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                            v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                              v-html="data.item.criteria"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </div>
              </div>

              <div v-if="selectTagType == 2">
                <label style="font-weight: bold">
                  {{ $t("Reason") }}
                </label>
                <div>
                  <v-autocomplete
                      filled
                      chips
                      color="blue-grey lighten-2"
                      item-text="name"
                      item-value="id"
                      v-model="goodUseTagsSelected"
                      :items="goodUseTags"
                      multiple
                      @change="updateTags(2)"
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                            v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                              v-html="data.item.criteria"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </div>
              </div>

              <div>
                <!--                <div :class="{'hide':selectTagType==2}" class="half">-->
                <div :class="{ hide: selectTagType == 2 }" class="full">
                  <div
                      v-if="suggestReplacements.length > 0 && isSuggestionPopup"
                  >
                    <label style="font-weight: bold" class="lock">{{
                        $t("Replace Suggestions")
                      }}</label>
                    <div
                        style="
                        display: flex;
                        justify-items: flex-start;
                        flex-wrap: wrap;
                        gap: 2px;
                      "
                    >
                      <span
                          class="suggest-replacement-item"
                          style="margin-left: 0px"
                          @click="_selectSuggestReplacement(item, index)"
                          v-for="(item, index) in suggestReplacements"
                          :class="{
                          active:
                            index == activeSuggestReplacementItemIndex &&
                            replaceValue == item,
                        }"
                          :key="index"
                      >{{ item }}</span
                      >
                    </div>
                  </div>

                  <label style="font-weight: bold" class="lock">{{
                      $t("Rewrite")
                    }}</label>

                  <v-textarea
                      style="width: 100%"
                      v-model="replaceValue"
                      placeholder="Rewrite"
                      solo
                      :disabled="!selectedText || disableReplace"
                  ></v-textarea>
                </div>

                <!--                <div :class="[{'half':selectTagType==1},{'full':selectTagType==2}]">-->
                <!--                <div class="full"
                                     label style="font-weight: bold;">{{ $t('Comment') }}</label>-->
                <div class="full">
                  <div
                      style="font-weight: bold"
                      v-if="
                      (selectTagType == 1 && suggestComment) ||
                      (selectTagType == 1 &&
                        errorTagsObjectSelected &&
                        errorTagsObjectSelected.length > 0)
                    "
                  >
                    Suggestion Comments
                  </div>

                  <div
                      v-if="
                      suggestComment && selectTagType == 1 && isSuggestionPopup
                    "
                      style="margin-top: 10px"
                  >
                    <strong style="font-width: semi-bold; color: #1bc5bd"
                    >Language Tool:&nbsp;&nbsp;</strong
                    >
                    <span>{{ suggestComment }}</span>
                    <span
                        style="
                        display: inline-block;
                        font-size: 12px;
                        margin-left: 5px;
                      "
                    >
                      <button
                          @click="_copyComment(suggestComment)"
                          style="
                          background: #1c6cff;
                          color: white;
                          padding: 5px;
                          border-radius: 5px;
                          font-weight: bold;
                          cursor: pointer;
                        "
                      >
                        Copy
                        <v-icon style="font-size: 14px; color: white"
                        >mdi-content-copy</v-icon
                        >
                      </button>
                    </span>
                  </div>

                  <div
                      v-if="
                      errorTagsObjectSelected &&
                      errorTagsObjectSelected.length > 0
                    "
                  >
                    <div
                        v-for="(item, index) in errorTagsObjectSelected"
                        :key="index"
                    >
                      <strong style="font-width: semi-bold; color: #1bc5bd"
                      >{{ item.name }}:&nbsp;&nbsp;</strong
                      >
                      <span v-if="item.comment">
                        {{ item.comment }}
                      </span>
                      <button
                          @click="_copyComment(item.comment)"
                          style="
                          background: #1c6cff;
                          color: white;
                          padding: 5px;
                          border-radius: 5px;
                          font-weight: bold;
                          cursor: pointer;
                        "
                      >
                        Copy
                        <v-icon style="font-size: 14px; color: white"
                        >mdi-content-copy
                        </v-icon
                        >
                      </button>
                    </div>
                  </div>

                  <label
                      style="font-weight: bold; margin-top: 10px"
                      class="lock"
                  >{{ $t("Comment") }}
                  </label>
                  <button
                      v-if="commentValue"
                      @click="commentValue = ''"
                      style="
                      margin-left: 5px;
                      background: #ff4b4b;
                      color: white;
                      padding: 5px;
                      border-radius: 5px;
                      font-weight: bold;
                      cursor: pointer;
                    "
                  >
                    Clear All
                    <v-icon style="font-size: 14px; color: white"
                    >mdi-delete
                    </v-icon
                    >
                  </button>
                  <v-textarea
                      style="width: 100%"
                      placeholder="Comment"
                      solo
                      v-model="commentValue"
                      :disabled="!selectedText"
                  ></v-textarea>
                </div>
              </div>
            </div>

            <div>
              <button
                  :disabled="!selectedText"
                  class="btn btn-success"
                  @click="save()"
              >
                Save
              </button>
              <button
                  :disabled="!selectedText"
                  style="margin-left: 5px"
                  class="btn btn-danger"
                  @click="clear()"
              >
                Delete
              </button>
              <button
                  :disabled="!selectedText"
                  style="margin-left: 5px"
                  class="btn btn-warning"
                  @click="_close()"
              >
                Close
              </button>
            </div>
          </div>
          <div class="prep-tooltip"></div>
        </div>
        <!--END BUBBLE BOX-->
        <div class="prep-dialog" :class="{ 'hide-dialog': !showDialog }">
          <div class="prep-backdrop"></div>
          <div class="content">
            <v-card class="mx-auto" max-width="344" outlined>
              <v-card-text>
                <p
                    style="font-size: 18px; line-height: 24px; text-align: center"
                >
                  {{ dialogMessage }}
                </p>
              </v-card-text>

              <v-card-actions style="display: flex; justify-content: flex-end">
                <v-btn text outlined @click="showDialog = false"> Agree</v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2" v-if="answer.marking_data && this.skillId==4"
    >
      <v-btn
          class="white--text"
          small
          color="light-blue"
          @click="dialogShowOriginAnswer = true"
      >Xem bài làm gốc của học viên
      </v-btn>
    </div>

    <div style="margin-top: 10px">
      <h4 style="font-weight: bold">Good uses</h4>
      <table class="table-marking">
        <thead>
        <tr>
          <td style="width: 100px">No</td>
          <td>Original</td>
          <td>Tags</td>
          <td>Comment</td>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(item, index) in goodUseMark" :key="index">
          <td style="font-weight: bold; width: 100px">
            <a :href="`#${item.marking_index}`">{{
                item.marking_index | getTag
              }}</a>
          </td>
          <td style="width: 30%; font-weight: bold">
            {{ item.original_content }}
          </td>
          <td>
              <span
                  class="green p-2 text-white rounded mx-1 my-1"
                  style="display: inline-block"
                  v-for="(tag, index) in _getTagsById(item.marking_tag_ids, 2)"
                  :key="index"
              >
                <ErrorTag :tag="tag"></ErrorTag>
              </span>
          </td>
          <td style="width: 30%">
            <v-textarea
                v-model="item.comment"
                placeholder="Comment"
                solo
                @keyup="_changeComment($event, item.marking_index)"
            ></v-textarea>
          </td>
        </tr>
        </tbody>
      </table>

      <br/>

      <h4 style="font-weight: bold">Errors</h4>
      <table class="table-marking">
        <thead>
        <tr>
          <td style="width: 100px">No</td>
          <td>Original</td>
          <td>Tags</td>
          <td>Rewrite</td>
          <td>Comment</td>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(item, index) in errorMark" :key="index">
          <td style="font-weight: bold; width: 100px" class="no">
            <a :href="`#${item.marking_index}`">{{
                item.marking_index | getTag
              }}</a>
          </td>
          <td style="width: 30%; font-weight: bold">
            {{ item.original_content }}
          </td>
          <td>
              <span
                  class="red p-2 rounded text-white mx-1 my-1"
                  style="display: inline-block"
                  v-for="(tag, index) in _getTagsById(item.marking_tag_ids, 1)"
                  :key="index"
              >
                <UseGoodTag :tag="tag"></UseGoodTag>
              </span>
          </td>
          <td style="width: 30%">
            <v-textarea
                v-model="item.edited_content"
                placeholder="Rewrite"
                solo
                @keyup="_changeEditedContent($event, item.marking_index)"
            ></v-textarea>
          </td>
          <td style="width: 30%">
            <v-textarea
                v-model="item.comment"
                placeholder="Comment"
                solo
                @keyup="_changeComment($event, item.marking_index)"
            ></v-textarea>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <!--    <button @click="_convertHTMLToActualData()">Get Output</button>-->
    <v-row justify="center">
      <v-dialog v-model="dialogShowOriginAnswer" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Câu trả lời gốc của học viên</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogShowOriginAnswer = false" right
              >mdi-close
              </v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <pre
                      class="content text-subtitle-1 text-justify font-weight-medium p-3"
                      style="border-radius: 5px"
                      v-html="rawContent"
                  ></pre>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
                class="btn text-uppercase mr-2"
                style="color: #f64e60"
                @click="dialogShowOriginAnswer = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import marking_trackpoint from "../../view/pages/marking-management/mixins/marking-trackpoint";

const EDIT_BOX_CLASS = "edit-box-wrapper";
import ApiService from "@/service/api.service";
import ErrorTag from "./ErrorTag";
import UseGoodTag from "./UseGoodTag";

export default {
  components: {
    ErrorTag,
    UseGoodTag,
  },
  props: {
    skillId: {
      default: 4,
    },
    rawContent: {
      default: "",
    },
    content: {
      default: `Slave spirituals often had hidden double meanings.
                On one level, spirituals referenced heaven, Jesus, and the
                soul, but on another level, the songs spoke about slave resistance.
                For example, according to Frederick Douglass, the song “O Canaan, /n
                Sweet Canaan” spoke of slaves’ longing for heaven, but it also expressed their
                desire to escape to the North. Careful listeners heard this second meaning in
                the following lyrics: “I don’t expect to stay / Much longer here. / Run to Jesus,
                shun the danger. / I don’t expect to stay.” When slaves sang this song, they
                could have been speaking of their departure from this life and their arrival in heaven;
                however, they also could have been describing their plans to leave the South and run, not
                to Jesus, but to the North. Slaves even used songs like “Steal Away to Jesus (at midnight)
                to announce to other slaves the time and place of secret, forbidden meetings.
                What whites heard as merely spiritual songs, slaves discerned as detailed messages.
                The hidden meanings in spirituals allowed slaves to sing what they could not say.`,
    },
    answer: null,
  },
  name: "Writing",
  mixins: [marking_trackpoint],
  data: function () {
    return {
      isSuggestionPopup: false,
      selectTagType: null,
      disableReplace: false,
      goodUseTagsSelected: [],
      errorTagsSelected: [],
      markingTagData: [],
      paragraph: "",
      selectedText: "",
      outputData: [],
      selection: null,
      range: null,
      isEdit: false,
      commentValue: "",
      markIndex: 0,
      replaceValue: "",
      showDialog: false,
      currentNode: null,
      dialogMessage: `Vui lòng điền đoạn văn thay thế hoặc bình luận trước khi lưu`,
      showEditBox: false,
      goodUseMark: [],
      errorMark: [],
      tagCounter: 0,
      characterCount: 0,
      wordCount: 0,
      sentenceCount: 0,
      suggestReplacements: [],
      suggestComment: null,
      activeSuggestReplacementItemIndex: null,
      dialogShowOriginAnswer: false,
    };
  },
  mounted: function () {
    console.log('mkmkmkmkmkmkmk: ', this.answer);


    this.getMarkingTagData();
    this.paragraph = this.content;
    this.rawParagraph = this.content;

    if (this.status == 1) {
      this._convertActualDataToHTML();
    }

    this.$nextTick(function () {
      setTimeout(() => {
        this._removeSup();
        this.addEventToHighlight();
        this._generateTagData();
      }, 1000);
    });

    this.characterCount = this.rawContent.length;
    const rawContent = this.rawContent.trim().replace("\n", "");
    this.wordCount = rawContent.split(" ").reduce((acc, cur) => {
      if (cur.trim() !== "") {
        acc++;
      }
      return acc;
    }, 0);
    this.sentenceCount = this.rawContent.trim().split(". ").length;
  },
  computed: {
    status() {
      return this.answer.status;
    },
    goodUseTags() {
      if (this.markingTagData) {
        return this.markingTagData?.good_use ?? null;
      }
      return null;
    },
    errorTags() {
      if (this.markingTagData) {
        return this.markingTagData.error;
      }
      return null;
    },
    annotations() {
      return this?.answer?.annotations ?? null;
    },
    errorTagsObjectSelected() {
      if (this.errorTagsSelected.length > 0) {
        const errorTagsObjectSelected = [];
        for (let i = 0; i < this.errorTagsSelected.length; i++) {
          const errorTag = this.errorTags.find(
              (item) => item.id == this.errorTagsSelected[i]
          );
          if (errorTag) {
            errorTagsObjectSelected.push(errorTag);
          }
        }
        return errorTagsObjectSelected;
      }
      return [];
    },
    origin() {
      const tmpDiv = document.createElement("div");
      if (this.$refs.paragraph.innerHTML) {
        tmpDiv.innerHTML = this.$refs.paragraph.innerHTML;
      } else {
        tmpDiv.innerHTML = this.rawContent;
      }
      const supTags = tmpDiv.querySelectorAll("sup");
      const spanTags = tmpDiv.querySelectorAll("span");
      supTags.forEach((item) => item.remove());
      spanTags.forEach((item) => item.remove());
      const markTags = tmpDiv.querySelectorAll("mark");
      markTags.forEach((item) => () => {
        const newNode = document.createTextNode(item.innerText);
        item.parentNode.insertBefore(newNode, item);
        item.remove();
      });
      return tmpDiv.innerText;
    },
  },
  watch: {
    "updateData.replace": function (newVal) {
      this.replaceText = newVal;
    },
    "updateData.commentValue": function (newVal) {
      this.commentValue = newVal;
    },
    goodUseTagsSelected(newVal) {
      if (newVal && newVal.length > 0) {
        this.disableReplace = true;
        this.replaceValue = "";
      } else {
        this.disableReplace = false;
      }
    },
    selectTagType(newValue, oldValue) {
      if (newValue != oldValue) {
        /* this.errorTagsSelected = [];
         this.goodUseTagsSelected = [];*/
      }
    },
  },
  methods: {
    _copyComment(suggestComment) {
      if (this.commentValue) {
        this.commentValue += `\n${suggestComment}`;
      } else {
        this.commentValue = suggestComment;
      }
    },
    _selectSuggestReplacement(item, index) {
      this.currentNode.setAttribute("active-suggest-replacement-index", index);
      this.currentNode.setAttribute("suggest", true);
      this.activeSuggestReplacementItemIndex = index;
      this.replaceValue = item;
    },
    _removeUnusedSuggestTags() {
      const writingMarkerNode = this.$refs.writingMarker;
      const markNodes = writingMarkerNode.querySelectorAll("mark");
      markNodes.forEach((item) => {
        if (item.querySelector(".suggest-replacement-item")) {
          const selected = item.getAttribute("prep-selected");
          const newNode = document.createTextNode(selected);
          item.parentNode.insertBefore(newNode, item);
          item.remove();
        }
      });
    },
    // _getOrigin() {
    //   const tmpDiv = document.createElement('div');
    //   tmpDiv.innerHTML = this.$refs.writingMarker.innerHTML;
    //   const supTags = tmpDiv.querySelectorAll('sup');
    //   const spanTags = tmpDiv.querySelectorAll('span');
    //   supTags.forEach(item => item.remove());
    //   spanTags.forEach(item => item.remove());
    //   const markTags = tmpDiv.querySelectorAll('mark');
    //   markTags.forEach(item => () => {
    //     const newNode = document.createTextNode(item.innerText);
    //     item.parentNode.insertBefore(newNode, item);
    //     item.remove()
    //   })
    //   return tmpDiv.innerText;
    // },
    _convertHTMLToActualData() {
      this._removeUnusedSuggestTags();
      const writingMarkerNode = this.$refs.writingMarker;
      const nodes = Array.from(writingMarkerNode.querySelectorAll("mark"));
      const actualData = nodes.map((mark) => {
        //let comment = mark.getAttribute('prep-comment');
        //mark.removeAttribute('prep-comment');
        let splitData = writingMarkerNode.innerHTML.split(mark.outerHTML);
        if (splitData[1] != undefined) {
          const selected = mark.getAttribute("prep-selected");
          /*  splitData = splitData[1].replaceAll(/(<span\b[^>]*>)[^<>]*(<\/span>)/ig, "")*/
          /*          splitData = splitData[1].replaceAll(/<\/?span[^]*>/ig, "")
                        //.replaceAll(/(<sup\b[^>]*>)[^<>]*(<\/sup>)/ig, "")
                        .replaceAll(/<\/?sup[^]*>/ig, "")
                        .replaceAll(/<[^>]*>/ig, "");//replace mark*/
          const tmpDiv = document.createElement("div");
          tmpDiv.innerHTML = splitData[0];
          const supTags = tmpDiv.querySelectorAll("sup");
          const spanTags = tmpDiv.querySelectorAll("span");
          supTags.forEach((item) => item.remove());
          spanTags.forEach((item) => item.remove());
          const markTags = tmpDiv.querySelectorAll("mark");
          markTags.forEach((item) => () => {
            const newNode = document.createTextNode(item.innerText);
            item.parentNode.insertBefore(newNode, item);
            item.remove();
          });

          /*    const newNode = document.createTextNode(selected);
              item.parentNode.insertBefore(newNode
                  , item);*/

          //console.log(splitData);
          // console.log(tmpDiv.innerText);
          // console.log(this.rawContent);
          // console.log(this.origin.indexOf(tmpDiv.innerText));
          const offset = tmpDiv.innerText.length;
          tmpDiv.remove();
          const nextSup = mark.nextElementSibling;
          let nextSupInfo = null;
          if (nextSup && nextSup.tagName.toLowerCase() == "sup") {
            nextSupInfo = {
              text: nextSup.innerText,
              id: nextSup.getAttribute("id"),
            };
          }
          /*const markingTagsIdsGoodUse = mark.getAttribute('marking-tag-ids-good-use')?.split('|') ?? [];
          markingTagsIdsGoodUse.filter(item => item != 'NaN');
          const markingTagsIdsError = mark.getAttribute('marking-tag-ids-good-use')?.split('|') ?? [];
          markingTagsIdsError.filter(item => item != 'NaN');*/
          return {
            selected: selected,
            replaced: mark.getAttribute("prep-replace") ?? null,
            markingTagIdsGoodUse:
                mark
                    .getAttribute("marking-tag-ids-good-use")
                    ?.replaceAll("|NaN", "")
                    ?.replaceAll("|NaN|", "||")
                    ?.replaceAll("NaN|", "|") ?? null,
            //markingTagIdsGoodUse: markingTagsIdsGoodUse.join('|'),
            markingTagIdsError:
                mark
                    .getAttribute("marking-tag-ids-error")
                    ?.replaceAll("|NaN", "")
                    ?.replaceAll("|NaN|", "||")
                    ?.replaceAll("NaN|", "|") ?? null,
            //markingTagIdsError: markingTagsIdsError.join('|'),
            offset,
            comment: mark.getAttribute("prep-comment"),
            length: selected.length,
            nextSupInfo,
            index: mark.getAttribute("prep-mark-index"),
            useSuggest: mark.getAttribute("suggest") ? true : false,
          };
        }
      });
      console.log(actualData);
      return actualData;
    },
    _convertActualDataToHTML() {
      let contentArr = this.origin.split("");
      let subLength = 0;
      this.annotations.forEach((item) => {
        if (item.suggest) {
          const offset = item.offset;
          let length = item.length;
          let className = "no-select prep-highlight prep-comment";

          if (item.replaced) {
            className += " prep-replace";
          }
          // class="prep-highlight no-select good-use-highlight tag-highlight prep-replace"
          if (item.markingTagIdsGoodUse) {
            className += " good-use-highlight tag-highlight";
          }
          if (item.markingTagIdsError) {
            className += " error-highlight tag-highlight";
          }

          /*let startMark = `<mark prep-comment="${item.comment}" class="${className}" prep-selected="${item.selected}" prep-replace="${item.replaced}"
                    marking-tag-ids-good-use="${item.markingTagIdsGoodUse}" marking-tag-ids-error="${item.markingTagIdsError}">`;
*/
          //if (item.suggest) {
          let startMark = `<mark active-suggest-replacement-index='0' suggest_replacements='${item.suggest_replacements.join(
              "|"
          )}' suggest_comment='${item.suggest_comment}'`;
          if (item.comment) {
            startMark += `prep-comment="${item.comment}" `;
          }
          startMark += ` class='${className}' prep-selected='${item.selected}' prep-replace='${item.replaced}'
                    marking-tag-ids-error='${item.markingTagIdsError}'>`;
          //}
          /*          if (!item.suggest) {
                      if (item.selected && item.replaced) {
                        startMark += `<del class="replace-line">${item.selected}</del><span class="correct-line">${item.replaced}</span>`;
                      } else if (item.selected && !item.replaced) {
                        startMark += `<del style="text-decoration: none;">${item.selected}</del><span class="correct-line"></span>`;
                      }
                    } else {*/
          let replacementChoices = "";
          if (item.selected && item.suggest_replacements) {
            item.suggest_replacements.forEach((suggest, index) => {
              if (index === 3) {
                return;
              }
              replacementChoices += `<span class='suggest-replacement-item'>${suggest}</span>`;
            });
            startMark += `<del class='replace-line'>${item.selected}</del><span class='correct-line'>${replacementChoices}</span>`;
          }
          //}
          let mark = `${startMark}</mark>`;
          console.log(item.nextSupInfo);
          if (item.nextSupInfo) {
            mark += ` <sup id="${item.nextSupInfo.id}">${item.nextSupInfo.text}</sup>`;
          }
          contentArr.splice(offset - subLength, length, mark);
          subLength = subLength + length - 1;
        }
      });
      this.paragraph = contentArr.join("");
      console.log(this.paragraph);
    },
    updateTags(type) {
      //1 = error
      //2 = goodusse
      if (type == 1) {
        this.goodUseTagsSelected = [];
      }

      if (type == 2) {
        this.errorTagsSelected = [];
      }
    },
    async getMarkingTagData() {
      try {
        const resp = await ApiService.get(
            "/prep-app/marking-tag/grouped-by-type?skill_id=" + this.skillId
        );
        this.markingTagData = resp.data;
      } catch (e) {
        console.log(e);
      }
    },
    mark() {
      //mark selection
      this.markIndex++;
      this.range = this.selection.getRangeAt(0);
      let insertNode = document.createElement("mark");
      insertNode.classList.add(`prep-highlight`);
      insertNode.classList.add(`no-select`);
      insertNode.classList.add(`temp-mark`);
      insertNode.setAttribute("prep-mark-index", this.markIndex);

      const docContent = this.range.cloneContents();
      if (docContent.querySelector("mark")) {
        this.dialogMessage = `Vui lòng chọn những đoạn văn chưa được đánh dấu`;
        this.showDialog = true;
        this._reset();
        return;
      }

      insertNode.appendChild(this.range.extractContents());
      this.range.insertNode(insertNode);
      this.currentNode = insertNode;
      this.showEditBox = true;
    },
    removeUselessHighlight() {
      const nodes =
          this.$refs.writingMarker.getElementsByClassName(`prep-highlight`);
      if (nodes != null && nodes.length > 0) {
        for (let i = 0; i < nodes.length; i++) {
          const node = nodes[i];
          if (
              !node.classList.contains("prep-comment") &&
              !node.classList.contains("prep-replace")
          ) {
            const text = node.innerText;
            const textNode = document.createTextNode(text);
            node.parentNode.replaceChild(textNode, node);
          }
        }
      }
    },
    addEventToHighlight: function () {
      //remove Highlight
      const that = this;
      console.log(this);
      const markNodes = this.$refs.writingMarker.getElementsByTagName("mark");
      const handleHighlightClick = (evt) => {
        that.goodUseTagsSelected = [];
        that.errorTagsSelected = [];
        //that.errorTagsSelected.push(1, 2, 3);
        that.isEdit = true;
        that.showEditBox = true;
        that.positionBox(evt, EDIT_BOX_CLASS, true);
        that.commentValue = evt.target.getAttribute("prep-comment");
        that.selectedText = evt.target.getAttribute("prep-selected");
        if (
            evt.target.getAttribute("prep-replace") &&
            evt.target.getAttribute("prep-replace") != "null"
        ) {
          that.replaceValue = evt.target.getAttribute("prep-replace") ?? "";
        }

        const markHighlight = evt.target.closest(".prep-highlight");
        const suggestComment = markHighlight.getAttribute("suggest_comment");
        if (suggestComment) {
          this.suggestComment = suggestComment;
          this.isSuggestionPopup = true;
        }

        if (markHighlight.getAttribute("suggest_replacements")) {
          const activeSuggestReplacementIndex = markHighlight.getAttribute(
              "active-suggest-replacement-index"
          );
          const suggestReplacements = markHighlight.getAttribute(
              "suggest_replacements"
          );
          if (markHighlight.getAttribute("suggest_replacements")) {
            this.isSuggestionPopup = true;
          }
          this.activeSuggestReplacementItemIndex =
              activeSuggestReplacementIndex;
          if (this.suggestReplacements) {
            this.suggestReplacements = suggestReplacements?.split("|");
          }
          /*          if (this.replaceValue == 'null') {
                      this.replaceValue = this.suggestReplacements[this.activeSuggestReplacementItemIndex];
                    }*/
        } else {
          this.suggestReplacements = [];
        }
        if (markHighlight.getAttribute("marking-tag-ids-error")) {
          const tags = markHighlight
              .getAttribute("marking-tag-ids-error")
              ?.split("|");
          that.selectTagType = 1;
          that.errorTagsSelected = [];
          for (let j = 0; j < tags.length; j++) {
            if (!isNaN(tags[j])) {
              console.log(typeof that.errorTagsSelected);
              that.errorTagsSelected.push(parseInt(tags[j]));
            }
          }
        }
        if (markHighlight.getAttribute("marking-tag-ids-good-use")) {
          const tags = markHighlight
              .getAttribute("marking-tag-ids-good-use")
              ?.split("|");
          that.selectTagType = 2;
          that.goodUseTagsSelected = [];
          for (let j = 0; j < tags.length; j++) {
            if (!isNaN(tags[j])) {
              that.goodUseTagsSelected.push(parseInt(tags[j]));
            }
          }
        }
        that.currentNode = evt.target;
      };

      for (let i = 0; i < markNodes.length; i++) {
        const node = markNodes[i];
        if (
            node.classList.contains("prep-comment") ||
            node.classList.contains("prep-replace")
        ) {
          node.removeEventListener("click", handleHighlightClick);
          node.addEventListener("click", handleHighlightClick);
          if (node.classList.contains("prep-comment")) {
            node.addEventListener("mouseenter", function (evt) {
              let tooltip = document.querySelector(".prep-tooltip");
              let comment = evt.target.getAttribute("prep-comment");
              if (comment != null) {
                tooltip.innerHTML = comment;
                that.positionBox(evt, "prep-tooltip", true);
                tooltip.style.display = "block";
              }
            });

            node.addEventListener("mouseleave", function () {
              let tooltip = document.querySelector(".prep-tooltip");
              tooltip.style.display = "none";
            });
          }
        }
      }
    },
    save: function () {
      //nếu cả bình luận và thay thế đều bằng null
      /*      if (!this.replaceValue && !this.commentValue) {
              this.showDialog = true;
              return;
            }*/

      this._removeSup();
      const that = this;
      this.currentNode.classList.remove("temp-mark");
      if (that.errorTagsSelected.length > 0) {
        that.errorTagsSelected = that.errorTagsSelected.filter(
            (item) => !isNaN(item)
        );
        that.currentNode.setAttribute(
            `marking-tag-ids-error`,
            that.errorTagsSelected.join("|") + ""
        );
        that.currentNode.classList.add("error-highlight", "tag-highlight");
      } else if (that.goodUseTagsSelected.length > 0) {
        that.goodUseTagsSelected = that.goodUseTagsSelected.filter(
            (item) => !isNaN(item)
        );
        that.currentNode.setAttribute(
            `marking-tag-ids-good-use`,
            that.goodUseTagsSelected.join("|") + ""
        );
        that.currentNode.classList.add("good-use-highlight", "tag-highlight");
      }

      if (that.errorTagsSelected.length == 0) {
        that.currentNode.removeAttribute("marking-tag-ids-error");
        that.currentNode.classList.remove("error-highlight");
      }

      if (that.goodUseTagsSelected.length == 0) {
        that.currentNode.removeAttribute("marking-tag-ids-good-use");
        that.currentNode.classList.remove("good-use-highlight");
      }

      const addComment = () => {
        if (that.commentValue) {
          if (that.isEdit) {
            that.currentNode.setAttribute("prep-comment", that.commentValue);
          } else {
            that.currentNode.setAttribute(`prep-comment`, that.commentValue);
            that.currentNode.classList.add("prep-comment");
          }
        } else {
          that.currentNode.removeAttribute("prep-comment");
        }
        that.isEdit = false;
        that.addEventToHighlight();
        that._reset();
      };
      that.currentNode.setAttribute(`prep-selected`, that.selectedText);
      const addDel = () => {
        //if (that.replaceValue) {
        if (that.isEdit) {
          //nếu là chỉnh sửa
          const replaceTag = that.currentNode.getElementsByTagName(`span`)[0];
          replaceTag.innerText = that.replaceValue;
          that.currentNode.setAttribute(`prep-replace`, that.replaceValue);
          addComment();
        } else {
          //lưu lại chỉnh sửa
          const selectedNode = document.createElement("span");
          selectedNode.classList.add("correct-line");
          selectedNode.innerText = that.replaceValue + " ";
          const delNode = document.createElement("del");
          delNode.innerText = that.selectedText;
          if (that.replaceValue) {
            delNode.classList.add("replace-line");
          }
          that.currentNode.innerHTML = "";
          that.currentNode.classList.add("prep-replace");
          if (!that.replaceValue) {
            delNode.style.textDecoration = "none";
          }
          that.currentNode.appendChild(delNode);
          that.currentNode.appendChild(selectedNode);
          that.currentNode.setAttribute(`prep-replace`, that.replaceValue);
          addComment();
        }
        this.checkMarkerTrackpoint("annotate");
      };
      addDel();

      this._generateTagData();
    },
    getSelectionText: function (e) {
      //alert("Y");
      this.selectTagType = null;
      this.errorTagsSelected = [];
      this.goodUseTagsSelected = [];
      this._reset();
      if (window.getSelection().baseNode.parentNode.id !== "prep-para") {
        this._reset();
        return;
      }
      //alert("YY");
      this.selection = window.getSelection();
      const nodes = this.$refs.writingMarker.getElementsByTagName("mark");
      for (let i = 0; i < nodes.length; i++) {
        if (this.selection.containsNode(nodes[i])) {
          found = true;
          break;
        }
      }

      if (found) {
        this.dialogMessage = `Vui lòng chọn những đoạn văn chưa được đánh dấu`;
        this.showDialog = true;
        this._reset();
        return;
      }

      this.selectedText = this.selection.toString().replace(/<[^>]*>?/gm, "");
      if (
          !this.paragraph.replaceAll("\\<[^>]*>", "").includes(this.selectedText)
      ) {
        this._reset();
        return;
      }

      //alert("X");
      //di chuyển edit box vào vị trí edit

      let found = false;

      this.removeUselessHighlight();
      this.positionBox(e, EDIT_BOX_CLASS, true);

      if (this.selectedText && !found) {
        this.mark();
      }
      return false;
    },
    //di chuyển box
    positionBox: function (e, boxClass, positionX = false) {
      this.isSuggestionPopup = false;
      const position = this.getMouseCursor(e);
      const box = this.$refs.writingWrapper.querySelector("." + boxClass);
      if (positionX) {
        box.style.left = position.x + 10 + "px";
        box.style.top = position.y + 10 + "px";
      } else {
        box.style.top = position.y + "px";
      }
      if (
          box.getBoundingClientRect().right >
          this.$refs.writingMarker.getBoundingClientRect().right
      ) {
        box.style.left =
            this.$refs.writingMarker.getBoundingClientRect().width -
            box.getBoundingClientRect().width +
            "px";
      }
    },
    //lấy vị trí của chuột
    getMouseCursor: function (e) {
      const rect = this.$refs.writingMarker.getBoundingClientRect();
      const x = e.clientX - rect.left; //x position within the element.
      const y = e.clientY - rect.top; //y position within the element
      return {
        x,
        y,
      };
    },
    clear: function () {
      this._removeSup();
      if (this.isEdit) {
        const originText = this.currentNode.getAttribute("prep-selected");
        const originTextNode = document.createTextNode(originText);
        this.currentNode.parentNode.replaceChild(
            originTextNode,
            this.currentNode
        );
      }
      this.goodUseTagsSelected = [];
      this.errorTagsSelected = [];
      this._reset();
      this.tagCounter = 0;
      this._generateTagData();
    },
    _close: function () {
      this.showEditBox = false;
      this.removeUselessHighlight();
      this.goodUseTagsSelected = [];
      this.errorTagsSelected = [];
    },
    _reset: function () {
      this.selectedText = "";
      this.currentNode = "";
      this.selection = null;
      this.replaceValue = "";
      this.commentValue = "";
      this.isEdit = false;
      this.showEditBox = false;
      this.removeUselessHighlight();
    },
    _changeComment(event, markingIndex) {
      const comment = event.target.value;
      const updateMarkNode = this.$refs.writingMarker.querySelector(
          `#${markingIndex}`
      ).previousElementSibling;
      updateMarkNode.setAttribute("prep-comment", comment);
    },
    _changeEditedContent(event, markingIndex) {
      const replaceContent = event.target.value;
      const updateMarkNode = this.$refs.writingMarker.querySelector(
          `#${markingIndex}`
      ).previousElementSibling;
      updateMarkNode.setAttribute("prep-replace", replaceContent);
      updateMarkNode.querySelector(".correct-line").innerText = replaceContent;
    },
    convertMarkNodeToData: function (node) {
      let text = "";
      let replace = "";
      let comment = "";
      let index = 0;

      if (node.getElementsByClassName("prep-replace").length > 0) {
        replace = node.getElementsByClassName("prep-replace")[0].innerText;
      }

      if (node.getElementsByClassName("prep-strike").length > 0) {
        text = node.getElementsByClassName("prep-strike")[0].innerText;
      } else {
        text = node.innerText;
      }

      if (node.getAttribute("prep-comment") != null) {
        comment = node.getAttribute("prep-comment");
      }

      index = node.getAttribute("prep-mark-index");
      let obj = {
        replace: replace,
        source: text,
        comment: comment,
        index,
      };
      return obj;
    },
    _getTagsById(ids, type) {
      //type
      //1 = error
      //2 = good usse
      const filterTags = [];
      const tags = type == 1 ? this.errorTags : this.goodUseTags;
      if (tags) {
        for (let i = 0; i < ids.length; i++) {
          const tag = tags.find((item) => item.id == ids[i]);
          if (tag) {
            filterTags.push(tag);
          }
        }
        return filterTags;
      }
    },
    _removeSup: function () {
      const supNodes = this.$refs.writingMarker.querySelectorAll("sup");
      const length = supNodes.length;
      for (let i = 0; i < length; i++) {
        if (supNodes[i]) {
          supNodes[i].remove();
        }
      }
    },
    _generateTagData() {
      this._removeSup();
      this.errorMark = [];
      this.goodUseMark = [];
      const parentNode = this.$refs.writingMarker;
      const content = parentNode.querySelector(".content");
      const markers = content.querySelectorAll(".prep-highlight");

      this.tagCounter = 0;

      const _generateSupTag = (i) => {
        this.tagCounter++;
        const sup = document.createElement("sup");
        sup.setAttribute("id", `prep-tag-${this.answer.id}-${this.tagCounter}`);
        sup.innerText = this.tagCounter + "";
        markers[i].after(sup);
      };

      for (let i = 0; i < markers.length; i++) {
        if (markers[i].classList.contains("good-use-highlight")) {
          _generateSupTag(i);
          const markingTagIds = markers[i]
              .getAttribute("marking-tag-ids-good-use")
              .split("|");
          const goodUseTags = {
            original_content: markers[i]?.getAttribute("prep-selected"),
            marking_index: `prep-tag-${this.answer.id}-${this.tagCounter}`,
            marking_tag_ids: markingTagIds,
            comment: markers[i]?.getAttribute("prep-comment"),
          };
          this.goodUseMark.push(goodUseTags);
        } else if (markers[i].classList.contains("error-highlight")) {
          _generateSupTag(i);
          const markingTagIds = markers[i]
              .getAttribute("marking-tag-ids-error")
              .split("|");
          const errorTags = {
            original_content: markers[i]?.getAttribute("prep-selected"),
            marking_index: `prep-tag-${this.answer.id}-${this.tagCounter}`,
            marking_tag_ids: markingTagIds,
            comment: markers[i]?.getAttribute("prep-comment"),
            edited_content: markers[i]?.getAttribute("prep-replace"),
          };
          this.errorMark.push(errorTags);
        }
      }
    },
    //lấy về data
    getOutputData: function () {
      let parentNode = this.$refs.writingMarker;
      let content = parentNode.querySelector(".content");
      const markers = content.getElementsByClassName("prep-highlight");
      for (let i = 0; i < markers.length; i++) {
        let o = this.convertMarkNodeToData(markers[i]);
        this.outputData.push(o);
      }

      let data = {
        annotations: this._convertHTMLToActualData(),
        paragraph: this.$refs.paragraph.innerHTML,
        outputData: this.outputData,
        marking_tags: {
          good_use: this.goodUseMark,
          error: this.errorMark,
        },
      };
      console.log(data);
      return data;
    },
    //lấy về data cứ sau 1000 giây
    getIntervalOutputData: function () {
      let self = this;
      setInterval(function () {
        self.getOutputData();
        self.$emit("getIntervalOutputData", self.getOutputData);
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.writing-2 .writing-wrapper * {
  font-family: Montserrat, sans-serif !important;
}

.writing-wrapper {
  /*  .prep-highlight {
      background: yellow;
    }*/
  .temp-mark {
    background: gray;
  }

  .half {
    width: 50%;
  }

  .full {
    width: 100%;
  }

  .content {
    font-size: 14px !important;
    padding: 0px;
  }

  position: relative;

  .hide {
    display: none;
  }

  .invisiblity {
    visibility: hidden;
  }

  .lock {
    pointer-events: none !important;
  }

  .edit-box-wrapper {
    position: absolute;
    width: 80%;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 1px 6px 1px #888888;
    -webkit-box-shadow: 0px 1px 6px 1px #888888;
    -moz-box-shadow: 0px 1px 6px 1px #888888;
    z-index: 1000;

    .selected-paragraph {
      background: white;
      max-height: 200px;
      color: black;
      overflow-y: scroll;
      padding: 10px;
      font-size: 14px;
    }
  }

  .prep-comment[prep-replace=""] {
    background: #fcfcb2;
  }

  .good-use-highlight,
  .error-highlight {
    background: none;
  }

  .prep-tooltip {
    position: absolute;
    display: none;
    font-size: 14px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 4px 3px 2px 2px #00000052;
  }
}

mark {
  padding: 0px !important;
  background: none;
}

.prep-dialog {
  position: fixed;
  width: 100% !important;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .prep-backdrop {
    background: #0000004d;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.writing-marker {
  -webkit-box-shadow: 0px 1px 1px 1px #a5a5a561;
  box-shadow: 0px 1px 1px 1px #a5a5a561;
  background: white;
  position: relative;
  display: flex;
  justify-content: space-around;

  mark {
    cursor: pointer;
    background: none;

    .correct-line,
    &.good-use-highlight .correct-line {
      color: #4ad1ff !important;
    }

    del.replace-line {
      color: #c93333;
      background: none;
    }

    &.good-use-highlight * {
      color: #2ecc40;
    }

    &.error-highlight * {
      color: #c93333;
    }

    del {
      color: black;
    }
  }

  mark * {
    pointer-events: none;
  }
}

pre {
  white-space: pre-wrap !important; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -o-pre-wrap !important; /* Opera 7 */
  word-wrap: break-word !important; /* Internet Explorer 5.5+ */
}

.suggest-replacement-item {
  background: #b0b0b0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 5px;
  color: #0e0e0e;
  padding-top: 2px;
  padding-bottom: 2px;
}

.suggest-replacement-item.active {
  background: #1c6cff;
  color: white;
}

.suggest-replacement-item:hover {
  background: #1c6cff;
  color: white;
  cursor: pointer;
}

.hide-dialog {
  display: none;
}

.table-marking {
  padding: 20px;
  width: 100%;

  td {
    border: 1px dashed #cdcdcd;
    padding: 10px;
    height: 100%;
  }

  font-size: 12px !important;

  .tag {
    border-radius: 4px;
    background: green;
    color: white;
    padding: 5px;
    cursor: pointer;
  }

  .content {
    font-size: 16px !important;
    line-height: 12px;
  }
}

.phrase-plagiarism:hover .tooltip-plagiarism {
  display: block !important;
}

.tooltip-plagiarism {
  display: none !important;
  top: 0;
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
</style>
